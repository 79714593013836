import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  MerchantByIDErrorResponse, MerchantIDsResponse,
  MerchantsErrorResponse,
  MerchantsRequestParams,
  MerchantsResponse,
  PostMerchantErrorResponse,
  PostMerchantRequestParams,
  PutMerchantErrorResponse,
  PutMerchantRequestParams,
} from '../../api/merchants'
import Merchant, { MerchantFilters, MerchantStats } from '../../model/Merchant'
import PaginationInfo from '../../model/PaginationInfo'
import PaymentAccount from '../../model/PaymentAccount'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface SelectAllMerchantsOnPageAction {
  type: typeof types.SELECT_ALL_ON_PAGE
  payload: MerchantsRequestParams
}

export interface SelectAllMerchantsAction {
  type: typeof types.SELECT_ALL
  success: undefined
  payload: MerchantsRequestParams
}

export interface ResolveSelectAllMerchantsAction {
  type: typeof types.SELECT_ALL
  success: boolean
  payload: {
    merchantIDs: number[]
    requestParams: MerchantsRequestParams
  }
}

export interface RejectSelectAllMerchantsAction {
  type: typeof types.SELECT_ALL
  success: boolean
  payload: {
    error: Error
    requestParams: MerchantsRequestParams
  }
}

export interface DeselectAllMerchantsAction {
  type: typeof types.DESELECT_ALL
}

export interface ToggleSelectMerchantByID {
  type: typeof types.TOGGLE_SELECTION_BY_ID
  payload: number
}

export interface FetchMerchantsAction {
  type: typeof types.FETCH_MERCHANTS
  success: undefined
  payload: MerchantsRequestParams
}

export interface ResolveFetchMerchantsAction {
  type: typeof types.FETCH_MERCHANTS
  success: boolean
  payload: {
    merchants: Merchant[]
    paginationInfo: PaginationInfo
    requestParams: MerchantsRequestParams
  }
}

export interface RejectFetchMerchantsAction {
  type: typeof types.FETCH_MERCHANTS
  success: boolean
  payload: {
    error: Error
    requestParams: MerchantsRequestParams
  }
}

export interface FetchMerchantByIDAction {
  type: typeof types.FETCH_MERCHANT_BY_ID
  success: undefined
  payload: {
    merchantID: number
  }
}

export interface ResolveFetchMerchantByIDAction {
  type: typeof types.FETCH_MERCHANT_BY_ID
  success: boolean
  payload: {
    merchant: Merchant
  }
}

export interface RejectFetchMerchantByIDAction {
  type: typeof types.FETCH_MERCHANT_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      merchantID: number
    }
  }
}

export interface FetchMerchantStatsByIDAction {
  type: typeof types.FETCH_MERCHANT_STATS_BY_ID
  success: undefined
  payload: {
    merchantID: number
  }
}

export interface ResolveFetchMerchantStatsByIDAction {
  type: typeof types.FETCH_MERCHANT_STATS_BY_ID
  success: boolean
  payload: {
    stats: MerchantStats
  }
}

export interface RejectFetchMerchantStatsByIDAction {
  type: typeof types.FETCH_MERCHANT_STATS_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      merchantID: number
    }
  }
}

export interface UpdateMerchantAction {
  type: typeof types.UPDATE_MERCHANT
  success: undefined
  payload: PutMerchantRequestParams
}

export interface ResolveUpdateMerchantAction {
  type: typeof types.UPDATE_MERCHANT
  success: boolean
  payload: {
    merchant: Merchant
  }
}

export interface RejectUpdateMerchantAction {
  type: typeof types.UPDATE_MERCHANT
  success: boolean
  payload: PutMerchantErrorResponse
}


export interface DeleteMerchantAction {
  type: typeof types.DELETE_MERCHANT
  success: undefined
  payload: {
    merchantID: number
  }
}

export interface ResolveDeleteMerchantAction {
  type: typeof types.DELETE_MERCHANT
  success: boolean
  payload: {
    merchant: Merchant
  }
}

export interface RejectDeleteMerchantAction {
  type: typeof types.DELETE_MERCHANT
  success: boolean
  payload: MerchantByIDErrorResponse
}

export interface CreateMerchantAction {
  type: typeof types.CREATE_MERCHANT
  success: undefined
  payload: PostMerchantRequestParams
}

export interface ResolveCreateMerchantAction {
  type: typeof types.CREATE_MERCHANT
  success: boolean
  payload: {
    merchant: Merchant
  }
}

export interface RejectCreateMerchantAction {
  type: typeof types.CREATE_MERCHANT
  success: boolean
  payload: PostMerchantErrorResponse
}

export interface UpdateMerchantPaymentAccount {
  type: typeof types.UPDATE_MERCHANT_PAYMENT_ACCOUNT
  payload: {
    paymentAccount: PaymentAccount
  }
}


export const selectAllMerchantsOnPage = (sorting: string, page: number, limit: number, filters: MerchantFilters, search: string): SelectAllMerchantsOnPageAction => ({
  type:    types.SELECT_ALL_ON_PAGE,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const selectAllMerchants = (sorting: string, page: number, limit: number, filters: MerchantFilters, search: string): SelectAllMerchantsAction => ({
  type:    types.SELECT_ALL,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const deselectAllMerchants = (): DeselectAllMerchantsAction => ({
  type: types.DESELECT_ALL,
})

export const toggleMerchantIDSelection = (merchantID: number): ToggleSelectMerchantByID => ({
  type:    types.TOGGLE_SELECTION_BY_ID,
  payload: merchantID,
})

export const resolveSelectAllMerchants = ({
                                            merchantIDs,
                                            requestParams,
                                          }: MerchantIDsResponse): ResolveSelectAllMerchantsAction => ({
  type:    types.SELECT_ALL,
  success: true,
  payload: {
    merchantIDs,
    requestParams,
  },
})

export const rejectSelectAllMerchants = ({
                                           error,
                                           requestParams,
                                         }: MerchantsErrorResponse): RejectSelectAllMerchantsAction => ({
  type:    types.SELECT_ALL,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchMerchants = (sorting: string, page: number, limit: number, filters: MerchantFilters, search: string): FetchMerchantsAction => ({
  type:    types.FETCH_MERCHANTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const resolveFetchMerchants = ({
                                        merchants,
                                        paginationInfo,
                                        requestParams,
                                      }: MerchantsResponse): ResolveFetchMerchantsAction => ({
  type:    types.FETCH_MERCHANTS,
  success: true,
  payload: {
    merchants,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchMerchants = ({ error, requestParams }: MerchantsErrorResponse): RejectFetchMerchantsAction => ({
  type:    types.FETCH_MERCHANTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchMerchantByID = (merchantID: number): FetchMerchantByIDAction => ({
  type:    types.FETCH_MERCHANT_BY_ID,
  success: undefined,
  payload: {
    merchantID,
  },
})

export const resolveFetchMerchantByID = (merchant: Merchant): ResolveFetchMerchantByIDAction => ({
  type:    types.FETCH_MERCHANT_BY_ID,
  success: true,
  payload: {
    merchant,
  },
})

export const rejectFetchMerchantByID = ({
                                          error,
                                          requestParams,
                                        }: MerchantByIDErrorResponse): RejectFetchMerchantByIDAction => ({
  type:    types.FETCH_MERCHANT_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})


export const fetchMerchantStatsByID = (merchantID: number): FetchMerchantStatsByIDAction => ({
  type:    types.FETCH_MERCHANT_STATS_BY_ID,
  success: undefined,
  payload: {
    merchantID,
  },
})

export const resolveFetchMerchantStatsByID = (stats: MerchantStats): ResolveFetchMerchantStatsByIDAction => ({
  type:    types.FETCH_MERCHANT_STATS_BY_ID,
  success: true,
  payload: {
    stats,
  },
})

export const rejectFetchMerchantStatsByID = ({
                                          error,
                                          requestParams,
                                        }: MerchantByIDErrorResponse): RejectFetchMerchantStatsByIDAction => ({
  type:    types.FETCH_MERCHANT_STATS_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateMerchant = (merchantID: number, merchantName: string): UpdateMerchantAction => ({
  type:    types.UPDATE_MERCHANT,
  success: undefined,
  payload: {
    merchantID,
    merchantName,
  },
})

export const resolveUpdateMerchant = (merchant: Merchant): ResolveUpdateMerchantAction => ({
  type:    types.UPDATE_MERCHANT,
  success: true,
  payload: {
    merchant,
  },
})

export const rejectUpdateMerchant = (payload: PutMerchantErrorResponse): RejectUpdateMerchantAction => ({
  type:    types.UPDATE_MERCHANT,
  success: false,
  payload,
})


export const deleteMerchant = (merchantID: number): DeleteMerchantAction => ({
  type:    types.DELETE_MERCHANT,
  success: undefined,
  payload: {
    merchantID,
  },
})

export const resolveDeleteMerchant = (merchant: Merchant): ResolveDeleteMerchantAction => ({
  type:    types.DELETE_MERCHANT,
  success: true,
  payload: {
    merchant,
  },
})

export const rejectDeleteMerchant = (payload: MerchantByIDErrorResponse): RejectDeleteMerchantAction => ({
  type:    types.DELETE_MERCHANT,
  success: false,
  payload,
})


export const createMerchant = (merchantName: string, brn: string, managerName:string, emailAddress: string , branchName: string, branchAddress: string,  branchPhoneNumber: string, terminalSerialNumber: string): CreateMerchantAction => ({
  type:    types.CREATE_MERCHANT,
  success: undefined,
  payload: {
    merchantName,
    brn,
    managerName,
    emailAddress,
    branchName,
    branchAddress,
    branchPhoneNumber,
    terminalSerialNumber
  },
})

export const resolveCreateMerchant = (merchant: Merchant): ResolveCreateMerchantAction => ({
  type:    types.CREATE_MERCHANT,
  success: true,
  payload: {
    merchant,
  },
})

export const rejectCreateMerchant = (payload: PostMerchantErrorResponse): RejectCreateMerchantAction => ({
  type:    types.CREATE_MERCHANT,
  success: false,
  payload,
})

export const updateMerchantPaymentAccount = (paymentAccount: PaymentAccount): UpdateMerchantPaymentAccount => ({
  type:    types.UPDATE_MERCHANT_PAYMENT_ACCOUNT,
  payload: {
    paymentAccount,
  },
})

export type MerchantsActions =
  FetchMerchantsAction
  | RejectFetchMerchantsAction
  | ResolveFetchMerchantsAction
  | FetchMerchantByIDAction
  | ResolveFetchMerchantByIDAction
  | RejectFetchMerchantByIDAction
  | FetchMerchantStatsByIDAction
  | ResolveFetchMerchantStatsByIDAction
  | RejectFetchMerchantStatsByIDAction
  | UpdateMerchantAction
  | RejectUpdateMerchantAction
  | ResolveUpdateMerchantAction
  | CreateMerchantAction
  | ResolveCreateMerchantAction
  | RejectCreateMerchantAction
  | DeleteMerchantAction
  | ResolveDeleteMerchantAction
  | RejectDeleteMerchantAction
  | ClearFlashMessagesAction
  | SelectAllMerchantsAction
  | ResolveSelectAllMerchantsAction
  | RejectSelectAllMerchantsAction
  | DeselectAllMerchantsAction
  | ToggleSelectMerchantByID
  | SelectAllMerchantsOnPageAction
  | UpdateMerchantPaymentAccount
  | LocationChangeAction
