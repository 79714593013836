import { Money, Currencies } from 'ts-money'
import Customer, { CustomerJSON } from './Customer'
import Employee, { EmployeeJSON } from './Employee'
import InvoiceItem, { InvoiceItemJSON } from './InvoiceItem'
import PartnerAgent, { PartnerAgentJSON } from './PartnerAgent'
import Vehicle, { VehicleJSON } from './Vehicle'

export default class Invoice {
  id: number
  invoiceNumber: string
  branchID: number
  customer: Customer | null
  vehicle: Vehicle | null
  items: InvoiceItem[]

  subtotal: Money
  discount: Money
  tax: Money
  total: Money

  createdAt: Date

  payments: Payment[]
  paidAt: Date | null

  notes: string | null
  internalNotes: string | null

  sellingEmployee: Employee | null
  sellingPartnerAgent: PartnerAgent | null

  constructor(json: InvoiceJSON) {
    this.id = json.id
    this.invoiceNumber = json.invoiceNumber
    this.branchID = json.branchID

    this.customer = null
    if (json.customer != null) {
      this.customer = new Customer(json.customer)
    }

    this.vehicle = null
    if (json.vehicle != null) {
      this.vehicle = new Vehicle(json.vehicle)
    }

    this.items = json.items.map(invoiceItemJSON => new InvoiceItem(invoiceItemJSON))

    this.subtotal = Money.fromInteger(json.subtotalAmount, Currencies.MYR)
    this.discount = Money.fromInteger(json.discountAmount, Currencies.MYR)
    this.tax = Money.fromInteger(json.taxAmount, Currencies.MYR)
    this.total = Money.fromInteger(json.totalAmount, Currencies.MYR)

    this.createdAt = new Date(json.createdAt)

    this.payments = json.payments.map(paymentJSON => new Payment(paymentJSON))
    this.paidAt = json.paidAt != null ? new Date(json.paidAt) : null

    this.notes = json.notes
    this.internalNotes = json.internalNotes

    this.sellingEmployee = null
    if (json.sellingEmployee != null) {
      this.sellingEmployee = new Employee(json.sellingEmployee)
    }

    this.sellingPartnerAgent = null
    if (json.sellingPartnerAgent != null) {
      this.sellingPartnerAgent = new PartnerAgent(json.sellingPartnerAgent)
    }
  }

  get [Symbol.toStringTag]() {
    return 'Invoice'
  }
}

export interface InvoiceJSON {
  id: number
  invoiceNumber: string
  branchID: number
  customer: CustomerJSON | null
  vehicle: VehicleJSON | null
  items: InvoiceItemJSON[]
  subtotalAmount: number
  discountAmount: number
  taxAmount: number
  totalAmount: number
  createdAt: string
  payments: PaymentJSON[]
  paidAt: string | null
  notes: string | null
  internalNotes: string | null
  sellingEmployee: EmployeeJSON | null
  sellingPartnerAgent: PartnerAgentJSON | null
}

export class PaymentMethod {
  static Invalid = new PaymentMethod('invalid', 'Invalid')
  static Cash = new PaymentMethod('cash', 'Cash')
  static Card = new PaymentMethod('card', 'Credit/Debit Card')
  static DuitNow = new PaymentMethod('duitnow', 'DuitNow')
  static TouchNGo = new PaymentMethod('tng', 'Touch \'n Go')
  static BankTransfer = new PaymentMethod('banktransfer', 'Bank Transfer')

  id: string
  name: string

  constructor(id: string, name: string) {
    this.id = id
    this.name = name
  }

  static byID = (promoTypeID: string): PaymentMethod => {
    switch (promoTypeID) {
      case PaymentMethod.Cash.id:
        return PaymentMethod.Cash

      case PaymentMethod.Card.id:
        return PaymentMethod.Card

      case PaymentMethod.DuitNow.id:
        return PaymentMethod.DuitNow
      
      case PaymentMethod.TouchNGo.id:
        return PaymentMethod.TouchNGo

      case PaymentMethod.BankTransfer.id:
        return PaymentMethod.BankTransfer

      default:
        return PaymentMethod.Invalid
    }
  }

  static All = [PaymentMethod.Cash, PaymentMethod.Card, PaymentMethod.DuitNow, PaymentMethod.TouchNGo, PaymentMethod.BankTransfer]
}

export class Payment {
  id: number
  branchID: number
  amount: Money
  createdAt: Date
  paymentMethod: PaymentMethod
  transactionID: string

  constructor(json: PaymentJSON) {
    this.id = json.id
    this.branchID = json.branchID


    this.amount = Money.fromInteger(json.amount, Currencies.MYR)
    this.createdAt = new Date(json.createdAt)
    this.paymentMethod = PaymentMethod.byID(json.paymentMethod)
    this.transactionID = json.transactionID

  }

  get [Symbol.toStringTag]() {
    return 'Invoice'
  }
}

export interface PaymentJSON {
  id: number
  branchID: number
  amount: number
  createdAt: string
  paymentMethod: string
  transactionID: string
}

export interface InvoiceFilters {
  dateRange: DateRangeFilter | null
  paymentMethod: PaymentMethod | null
  paymentStatus: boolean | null
  sellingPartnerAgent: number | null
  sellingEmployee: number | null
  branch: number | null
}

export interface DateRangeFilter {
  s: string
  e: string
}