import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'


export const postMerchantAuthToken = async (merchantID: number): Promise<PortalAuthToken> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}/auth-token`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({})

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response

    const { token, managerID } = await parseResponse(response) as PortalAuthToken
    return { token, managerID }
  } catch (err) {
    throw new LoopError(err, { merchantID })
  }
}

export interface PortalAuthToken {
  token: string,
  managerID: number
}

