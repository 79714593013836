
export default class Terminal {
  id: number
  name: string
  serialNumber: string
  branchID: number
  merchantID: number

  constructor(json: TerminalJSON) {
    this.id = json.id
    this.name = json.name
    this.serialNumber = json.serialNumber
    this.branchID = json.branchID
    this.merchantID = json.merchantID
  }

  get [Symbol.toStringTag]() {
    return 'Terminal'
  }
}

export interface TerminalJSON {
  id: number
  name: string
  serialNumber: string
  branchID: number
  merchantID: number
}
