import Administrator, { AdministratorJSON } from '../model/Administrator'
import Manager, { ManagerJSON } from '../model/Manager'
import Merchant from '../model/Merchant'
import Branch, { BranchJSON } from '../model/Branch'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'


export interface ProfileResponse {
  administrator: Administrator
}

export interface ProfileErrorResponse {
  error: Error
}
export const getProfile = async (period: string): Promise<ProfileResponse> => {

  // Build request
  const url = urlForEndpoint(`profile`)
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { administrator: administratorJSON } = await parseResponse(response)

    let administrator = new Administrator(administratorJSON)

    return {
      administrator,
    }
  } catch (err) {
    throw new LoopError(err, { period })
  }
}

export interface UpdateProfileErrorResponse {
  error: Error
  requestParams: UpdateProfileRequestParams
}

export interface UpdateProfileRequestParams {
  name: string
  emailAddress: string
}
export const putAdministrator = async (requestParams: UpdateProfileRequestParams): Promise<Manager> => {
  // Build request
  const url = urlForEndpoint(`administrator`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify(requestParams)

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { administrator } = await parseResponse(response)
    return new Administrator(administrator as AdministratorJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}


export interface ChangePasswordRequestParams {
  oldPassword: string
  newPassword: string
}

export interface ChangePasswordErrorResponse {
  error: Error
  requestParams: ChangePasswordRequestParams
}

export const postChangePassword = async (requestParams: ChangePasswordRequestParams): Promise<Manager> => {
  // Build request
  const url = urlForEndpoint(`change-password`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify(requestParams)

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { manager } = await parseResponse(response)
    return new Manager(manager as ManagerJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}
