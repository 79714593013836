import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, urlForEndpoint } from './helpers'

export const postLogIn = async (emailAddress: string, password: string): Promise<LogInResponse> => {

  // Build request
  const url = urlForEndpoint(`login`)
  const request = newRequest(HTTPMethods.POST)
  request.body = JSON.stringify({
    emailAddress,
    password,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {token, expire} = await parseResponse(response)
    let expiration = new Date(expire)

    return {token, expiration}
  } catch (err) {
    throw err
  }
}

export interface LogInResponse {
  token: string
  expiration: Date
}
