import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Merchant, { MerchantStats } from '../../model/Merchant'
import {
  CreateMerchantAction,
  DeleteMerchantAction,
  FetchMerchantByIDAction,
  FetchMerchantsAction,
  MerchantsActions,
  rejectCreateMerchant,
  RejectCreateMerchantAction,
  rejectDeleteMerchant,
  RejectDeleteMerchantAction,
  rejectFetchMerchantByID,
  RejectFetchMerchantByIDAction,
  rejectFetchMerchants,
  RejectFetchMerchantsAction,
  rejectUpdateMerchant,
  RejectUpdateMerchantAction,
  resolveCreateMerchant,
  ResolveCreateMerchantAction,
  resolveDeleteMerchant,
  ResolveDeleteMerchantAction,
  resolveFetchMerchantByID,
  ResolveFetchMerchantByIDAction,
  resolveFetchMerchants,
  ResolveFetchMerchantsAction,
  resolveUpdateMerchant,
  ResolveUpdateMerchantAction,
  UpdateMerchantAction,
  SelectAllMerchantsAction,
  resolveSelectAllMerchants,
  rejectSelectAllMerchants,
  ResolveSelectAllMerchantsAction,
  RejectSelectAllMerchantsAction,
  SelectAllMerchantsOnPageAction,
  FetchMerchantStatsByIDAction,
  resolveFetchMerchantStatsByID,
  rejectFetchMerchantStatsByID,
  ResolveFetchMerchantStatsByIDAction, RejectFetchMerchantStatsByIDAction, UpdateMerchantPaymentAccount,
} from '../actions/merchants'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForMerchantPage } from '../selectors/merchants'

interface IDMappedMerchants {
  [id: number]: Merchant
}

interface MerchantsReducerState {
  byID: IDMappedMerchants
  statsByID: Map<number, MerchantStats>
  isFetchingStatsByID: Map<number, boolean>
  pages: { [hash: string]: MerchantsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
  isSelectedByID: Map<number, boolean>
  isSelecting: boolean
  selectAllState: null | 'page' | 'all'
}

export class MerchantsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: MerchantsReducerState = {
  byID:                {},
  statsByID:           new Map<number, MerchantStats>(),
  isFetchingStatsByID: new Map<number, boolean>(),
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
  isSelectedByID:      new Map<number, boolean>(),
  isSelecting:         false,
  selectAllState:      null,
}

export default (state: MerchantsReducerState = initialState, action: MerchantsActions): MerchantsReducerState | Loop<MerchantsReducerState> => {
  switch (action.type) {

    case ROUTER_ON_LOCATION_CHANGED: {
      let { payload } = action as LocationChangeAction
      let { location } = payload
      let { selectAllState } = state
      if (location.pathname != '/merchants' && state.isSelectedByID.size > 0) {
        return {
          ...state,
          selectAllState: null,
          isSelectedByID: new Map<number, boolean>(),
        }
      } else {
        return state
      }
    }

    case actionTypes.TOGGLE_SELECTION_BY_ID: {
      let isSelectedByID = new Map(state.isSelectedByID)
      let { payload } = action

      isSelectedByID.set(payload, !(isSelectedByID.get(payload) || false))
      return {
        ...state,
        isSelectedByID,
        selectAllState: null,
      }
    }

    case actionTypes.DESELECT_ALL: {
      return {
        ...state,
        selectAllState: null,
        isSelectedByID: new Map<number, boolean>(),
      }
    }

    case actionTypes.SELECT_ALL_ON_PAGE: {
      const { selectAllState } = state
      let { payload } = action as SelectAllMerchantsOnPageAction

      const pageKey = hashKeyForMerchantPage(payload)
      let page = state.pages[pageKey]

      // New selection map
      let isSelectedByID = new Map<number, boolean>()

      page.childIDs.forEach(merchantID => {
        isSelectedByID.set(merchantID, true)
      })

      return {
        ...state,
        isSelectedByID,
        selectAllState: 'page',
      }
    }

    case actionTypes.SELECT_ALL: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          // Just select all on page at first
          const { selectAllState } = state
          let { payload } = action as SelectAllMerchantsAction
          if (selectAllState == null) {
            const pageKey = hashKeyForMerchantPage(payload)
            let page = state.pages[pageKey]

            // New selection map
            let isSelectedByID = new Map(state.isSelectedByID)

            page.childIDs.forEach(merchantID => {
              isSelectedByID.set(merchantID, true)
            })

            return {
              ...state,
              isSelectedByID,
            }
          } else if (selectAllState == 'page') {
            const { sorting, page, limit, filters, search } = payload

            // Set state and fetch
            return loop(
              Object.assign({}, state, {
                isSelecting: true,
              }),
              Cmd.run(API.getMerchantIDs, {
                successActionCreator: resolveSelectAllMerchants,
                failActionCreator:    rejectSelectAllMerchants,
                args:                 [sorting, page, limit, filters, search],
              }),
            )

          } else {
            return state
          }

        }

        case true: {
          let { payload } = action as ResolveSelectAllMerchantsAction
          const { merchantIDs, requestParams } = payload

          // New selection map
          let isSelectedByID = new Map(state.isSelectedByID)

          merchantIDs.forEach(merchantID => {
            isSelectedByID.set(merchantID, true)
          })

          // Place in correct page
          return {
            ...state,
            isSelectedByID,
            selectAllState: 'all',
            isSelecting:    false,
          }
        }

        case false: {
          const { payload } = action as RejectSelectAllMerchantsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForMerchantPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages:       Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
            isSelecting: false,
          })
        }
      }

    }

    case actionTypes.FETCH_MERCHANTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchMerchantsAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new MerchantsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForMerchantPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getMerchants, {
              successActionCreator: resolveFetchMerchants,
              failActionCreator:    rejectFetchMerchants,
              args:                 [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchMerchantsAction
          const { merchants, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForMerchantPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       merchants.map((merchant: Merchant) => merchant.id),
            paginationInfo: paginationInfo,
          }

          // Map merchant ids to merchants
          let idMappedMerchants: IDMappedMerchants = {}
          merchants.forEach(merchant => {
            idMappedMerchants[merchant.id] = merchant
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedMerchants),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchMerchantsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForMerchantPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_MERCHANT_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchMerchantByIDAction
          const { merchantID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [merchantID]: null,
              },
            },
            Cmd.run(API.getMerchantByID, {
              successActionCreator: resolveFetchMerchantByID,
              failActionCreator:    rejectFetchMerchantByID,
              args:                 [merchantID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchMerchantByIDAction
          const { merchant } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [merchant.id]: merchant,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchant.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchMerchantByIDAction
          const { error, requestParams } = payload
          const { merchantID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.FETCH_MERCHANT_STATS_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchMerchantStatsByIDAction
          const { merchantID } = payload

          let isFetchingStatsByID = new Map(Array.from(state.isFetchingStatsByID.entries()))
          isFetchingStatsByID.set(+merchantID, true)

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingStatsByID,
              errorByID: {
                ...state.errorByID,
                [merchantID]: null,
              },
            },
            Cmd.run(API.getMerchantStatsByID, {
              successActionCreator: resolveFetchMerchantStatsByID,
              failActionCreator:    rejectFetchMerchantStatsByID,
              args:                 [merchantID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchMerchantStatsByIDAction
          const { stats } = payload

          let isFetchingStatsByID = new Map(Array.from(state.isFetchingStatsByID.entries()))
          isFetchingStatsByID.set(+stats.merchantID, false)

          let statsByID = new Map(Array.from(state.statsByID))
          statsByID.set(+stats.merchantID, stats)

          return {
            ...state,
            isFetchingStatsByID,
            statsByID,
          }
        }

        case false: {
          const { payload } = action as RejectFetchMerchantStatsByIDAction
          const { error, requestParams } = payload
          const { merchantID } = requestParams

          let isFetchingStatsByID = new Map(Array.from(state.isFetchingStatsByID.entries()))
          isFetchingStatsByID.set(+merchantID, false)

          // Place in correct page
          return {
            ...state,
            // isFetchingStatsByID,
            errorByID: {
              ...state.errorByID,
              [merchantID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_MERCHANT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateMerchantAction
          const { merchantName, merchantID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchantID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [merchantID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putMerchant, {
              successActionCreator: resolveUpdateMerchant,
              failActionCreator:    rejectUpdateMerchant,
              args:                 [merchantID, merchantName],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateMerchantAction
          const { merchant } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [merchant.id]: merchant,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchant.id]: false,
              },
              successFlashMessage: 'Merchant successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateMerchantAction
          const { error, requestParams } = payload
          const { merchantID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_MERCHANT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteMerchantAction
          const { merchantID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [merchantID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [merchantID]: null,
              },
            },
            Cmd.run(API.deleteMerchant, {
              successActionCreator: resolveDeleteMerchant,
              failActionCreator:    rejectDeleteMerchant,
              args:                 [merchantID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteMerchantAction
          const { merchant } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[merchant.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [merchant.id]: false,
              },
              successFlashMessage: 'Merchant successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/merchants')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteMerchantAction
          const { error, requestParams } = payload
          const { merchantID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [merchantID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [merchantID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_MERCHANT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateMerchantAction
          const {
                  merchantName,
                  brn,
                  managerName,
                  emailAddress,
                  branchName,
                  branchAddress,
                  branchPhoneNumber,
                  terminalSerialNumber,
                } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postMerchant, {
              successActionCreator: resolveCreateMerchant,
              failActionCreator:    rejectCreateMerchant,
              args:                 [merchantName, brn, managerName, emailAddress, branchName, branchAddress, branchPhoneNumber, terminalSerialNumber],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateMerchantAction
          const { merchant } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [merchant.id]: merchant,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Merchant successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/merchants/${merchant.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateMerchantAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_MERCHANT_PAYMENT_ACCOUNT: {
      let { payload } = action as UpdateMerchantPaymentAccount
      let { paymentAccount } = payload

      // locate the merchant
      let merchant = state.byID[paymentAccount.merchantID]

      // locate the payment account
      let newPaymentAccounts = merchant.paymentAccounts.map(p => {
        if (p.id == paymentAccount.id) {
          return paymentAccount
        } else {
          return p
        }
      })

      let newMerchant = {
        ...merchant,
        paymentAccounts: newPaymentAccounts
      } as Merchant

      // Update stuff
      return {
        ...state,
        byID:      {
          ...state.byID,
          [merchant.id]: newMerchant,
        },
      }
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
