import { useTheme } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { RootReducerState } from '../../store'
import LoginPage from './LoginPage'

let AuthenticationWall = ({ children, isAuthenticated }: AuthenticationWallProps) => {
  const theme = useTheme(); // hack until I figure out why you can't call "useTheme()" in classes
  if (isAuthenticated) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return (
      <LoginPage theme={theme}/>
    )
  }
}

interface AuthenticationWallProps {
  children: any
  isAuthenticated: boolean
}

const mapStateToProps = (state: RootReducerState) => {
  return {
    isAuthenticated: state.authentication.token != null,
  }
}

export default connect(
  mapStateToProps,
)(AuthenticationWall)