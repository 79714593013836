import { Divider, Typography } from '@mui/material'
import React, { Component, ErrorInfo } from 'react'
import ErrorPage from '../ErrorPage'

export default class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      error:     null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // TODO: Log
    console.log('ErrorBoundary caught: ', error)

  }

  render() {
    let { error, errorInfo } = this.state
    if (errorInfo || error) {      // You can render any custom fallback UI
      return (
        <ErrorPage title="500" body="Whoops, something went
          wrong!">
          {error && (
            <>
              <code>
                {error.message}
                <br/>
              </code>
              <Divider sx={{ mt: 2, mb: 2 }}/>
            </>
          )}
          {errorInfo && (
            <>
              <pre><code>
                {errorInfo.componentStack}
                <br/>
              </code>
              </pre>
              <Divider sx={{ mt: 2, mb: 2 }}/>
            </>
          )}

          <Typography variant="body2" color="text.secondary" noWrap>
            Try refreshing the page, or going back and attempting the action again. If this problem persists, <a href="mailto:support@pitstop.my" className="ml-1">contact us</a>.
          </Typography>
          <p>

          </p>
        </ErrorPage>
      )
    }

    return this.props.children
  }
}

interface IProps {
}

interface IState {
  error: Error | null
  errorInfo: ErrorInfo | null
}