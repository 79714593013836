export default class Branch {
  id: number
  name: string
  address: string
  registrationNumber: string
  phoneNumber: string
  qrPaymentData: string
  receiptLogo: string

  constructor(json: BranchJSON) {
    this.id = json.id
    this.name = json.name
    this.address = json.address
    this.receiptLogo = json.receiptLogo
    this.registrationNumber = json.registrationNumber
    this.qrPaymentData = json.qrPaymentData
    this.phoneNumber = json.phoneNumber
  }

  get [Symbol.toStringTag]() {
    return 'Branch'
  }
}

export interface BranchJSON {
  id: number
  name: string
  address: string
  registrationNumber: string
  phoneNumber: string
  qrPaymentData: string
  receiptLogo: string
}
