import { LogInResponse } from '../../api/authentication'
import * as types from '../constants/ActionTypes'

interface LogOutAction {
  type: typeof types.LOG_OUT
}

export interface SubmitLogInFormAction {
  type: typeof types.SUBMIT_LOG_IN_FORM
  success: undefined
  payload: {
    emailAddress: string
    password: string
  }
}

export interface ResolveSubmitLogInFormAction {
  type: typeof types.SUBMIT_LOG_IN_FORM
  success: boolean
  payload: {
    token: string
    expiration: Date
  }
}

export interface RejectSubmitLogInFormAction {
  type: typeof types.SUBMIT_LOG_IN_FORM
  success: boolean
  payload: {
    error: Error
  }
}

export const submitLoginForm = (emailAddress: string, password: string): SubmitLogInFormAction => ({
  type:    types.SUBMIT_LOG_IN_FORM,
  success: undefined,
  payload: {
    emailAddress,
    password,
  },
})
export const resolveSubmitLoginForm = ({token, expiration}: LogInResponse): ResolveSubmitLogInFormAction => ({
  type:    types.SUBMIT_LOG_IN_FORM,
  success: true,
  payload: {
    token,
    expiration,
  },
})
export const rejectSubmitLoginForm = (error: Error): RejectSubmitLogInFormAction => ({
  type:    types.SUBMIT_LOG_IN_FORM,
  success: false,
  payload: {
    error,
  },
})

export type AuthenticationActionTypes =
  LogOutAction
  | SubmitLogInFormAction
  | ResolveSubmitLogInFormAction
  | RejectSubmitLogInFormAction

export const logOut = () => ({type: types.LOG_OUT})