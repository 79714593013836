import { createSelector } from 'reselect'
import { MerchantsRequestParams } from '../../api/merchants'
import Merchant, { MerchantStats } from '../../model/Merchant'
import { RootReducerState } from '../'
import { MerchantsReducerPage } from '../reducers/merchants'

export const hashKeyForMerchantPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     filters,
                                     search = "",
                                   }: MerchantsRequestParams): string => `${page},${limit},${sorting},${JSON.stringify(filters)},${search}`

const merchantsSelector = (state: RootReducerState): { [id: number]: Merchant } => state.merchants.byID

const merchantsStatsSelector = (state: RootReducerState): Map<number, MerchantStats> => state.merchants.statsByID
const merchantStatsIsFetchingByIDSelector = (state: RootReducerState): Map<number, boolean> => state.merchants.isFetchingStatsByID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.merchants.errorByID
const merchantIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.merchants.isFetchingByID

const merchantPageSelector = (state: RootReducerState, props: any): MerchantsReducerPage => state.merchants.pages[hashKeyForMerchantPage(props)] || new MerchantsReducerPage()
export const merchantFlashMessageSelector = (state: RootReducerState): string | null => state.merchants.successFlashMessage

// TODO: fix for react router
export const merchantIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.merchantID || -1
}

export const merchantWithIDFromProps = createSelector(
  [merchantsSelector, merchantIDFromPropsSelector],
  (merchants, merchantID) => merchants[merchantID],
)

export const merchantStatsWithIDFromProps = createSelector(
  [merchantsStatsSelector, merchantIDFromPropsSelector],
  (merchantStats, merchantID) => merchantStats.get(merchantID),
)

export const isFetchingStatsForMerchantWithIDFromProps = createSelector(
  [merchantStatsIsFetchingByIDSelector, merchantIDFromPropsSelector],
  (isFetching, merchantID) => isFetching.get(merchantID) || false,
)

export const errorForMerchantWithIDFromProps = createSelector(
  [errorsSelector, merchantIDFromPropsSelector],
  (merchants, merchantID) => merchants[merchantID],
)

export const isFetchingForMerchantWithIDFromProps = createSelector(
  [merchantIsFetchingByIDSelector, merchantIDFromPropsSelector],
  (merchants, merchantID) => merchants[merchantID] || false,
)

export const merchantsByPageSelector = createSelector(
  [merchantsSelector, merchantPageSelector],
  (merchants, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(merchantID => merchants[merchantID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [merchantPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [merchantPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [merchantPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [merchantPageSelector],
  (page) => page.error || null,
)

