import QueryString, { ParsedUrlQueryInput } from 'querystring'
import store from '../store'
import * as errors from './errors'

const API_HOST = process.env.API_HOST

export const token = (): string | null => {
  const {authentication} = store.getState()
  return authentication.token
}

export const urlForEndpoint = (endpoint: string, params: null | ParsedUrlQueryInput = null): string => {
  if (params == null) {
    return API_HOST + '/v1/administrator/' + endpoint
  } else {
    return API_HOST + '/v1/administrator/' + endpoint + '?' + QueryString.stringify(params)
  }
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const newRequest = (method: HTTPMethods, authToken: string | null = null, contentType = 'application/json'): RequestInit => {
  const headers = new Headers()
  headers.append('Content-Type', contentType)
  if (authToken !== null) {
    headers.append('Authorization', 'Bearer ' + authToken)
  }

  // Return fetch request body
  return {
    method,
    headers,
  }
}

export const fetchWithErrors = async (url: string, request: RequestInit): Promise<Response> => {
  try {
    return await fetch(url, request)
  } catch (err) {
    console.error('Failed to fetch:', err)
    throw errors.SERVER_UNREACHABLE
  }
}

export const parseResponse = async (response: Response): Promise<any> => {
  // If not successful, throw JSON as response
  let responseStatusNumber = Number(response.status)
  if (responseStatusNumber >= 400 && responseStatusNumber <= 599) {
    throw await response.json()
  }

  // Parse response
  let json
  try {
    json = await response.json()
  } catch (err) {
    console.error('Failed to fetch:', err)
    throw errors.INVALID_RESPONSE
  }

  // Handle empty JSON with prejudice
  if (json === null || json === undefined) {
    throw errors.INVALID_RESPONSE
  }

  return json
}
