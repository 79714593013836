export default class TerminalAuthorizationToken {
  terminalID: number
  token: string
  timeExpires: Date

  constructor(json: TerminalAuthorizationTokenJSON) {
    this.terminalID = json.terminalID
    this.token = json.token
    this.timeExpires = new Date(json.timeExpires)
  }

  get [Symbol.toStringTag]() {
    return 'Manager'
  }
}

export interface TerminalAuthorizationTokenJSON {
  terminalID: number
  token: string
  timeExpires: string
}
