
export default class Administrator {
  id: number
  name: string
  emailAddress: string

  constructor(json: AdministratorJSON) {
    this.id = json.id
    this.name = json.name
    this.emailAddress = json.emailAddress
  }

  get [Symbol.toStringTag]() {
    return 'Administrator'
  }
}

export interface AdministratorJSON {
  id: number
  name: string
  emailAddress: string
}
