import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { combineReducers } from 'redux-loop'
import { HistoryType } from '../history'
import authentication from './authentication'
import dashboard from './dashboard'
import merchants from './merchants'
import profile from './profile'
import ui from './ui'

export default (history: HistoryType) => {
  return combineReducers({
    // @ts-ignore
    router: createRouterReducer(history),
    // @ts-ignore
    dashboard,
    // @ts-ignore
    merchants,
    // @ts-ignore
    authentication,
    // @ts-ignore
    // @ts-ignore
    profile,
    // @ts-ignore
    ui,
  })
}