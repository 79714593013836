import PaginationInfo from '../model/PaginationInfo'
import Terminal, { TerminalJSON } from '../model/Terminal'
import TerminalAuthorizationToken, { TerminalAuthorizationTokenJSON } from '../model/TerminalAuthorizationToken'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const putTerminal = async (merchantID: number, terminalID: number, name: string, serialNumber: string): Promise<Terminal> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}/terminals/${terminalID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    serialNumber
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { terminal } = await parseResponse(response)
    return new Terminal(terminal as TerminalJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID, terminalID, name, serialNumber })
  }
}


export const postTerminalGenerateToken = async (merchantID: number, terminalID: number): Promise<TerminalAuthorizationToken> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}/terminals/${terminalID}/generate-token`)

  const request = newRequest(HTTPMethods.POST, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { token } = await parseResponse(response)
    return new TerminalAuthorizationToken(token as TerminalAuthorizationTokenJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID, terminalID })
  }
}

export interface TerminalIDsResponse {
  terminalIDs: number[]
  requestParams: TerminalsRequestParams
}


export interface TerminalsResponse {
  terminals: Terminal[]
  paginationInfo: PaginationInfo
  requestParams: TerminalsRequestParams
}

export interface TerminalsErrorResponse {
  error: Error
  requestParams: TerminalsRequestParams
}

export interface TerminalsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface TerminalByIDRequestParams {
  terminalID: number
}

export interface TerminalByIDErrorResponse {
  error: Error
  requestParams: TerminalByIDRequestParams
}

export interface PostTerminalRequestParams {
  terminalName: string
  brn: string
  managerName: string
  emailAddress: string
  branchName: string
  branchAddress: string
  branchPhoneNumber: string
}

export interface PostTerminalErrorResponse {
  error: Error
  requestParams: PostTerminalRequestParams
}

export interface PutTerminalRequestParams {
  terminalID: number
  terminalName: string
}

export interface PutTerminalErrorResponse {
  error: Error
  requestParams: PutTerminalRequestParams
}