import { postLogIn } from './authentication'
import { putBranch } from './branches'
import { getDashboard } from './dashboard'
import { postMerchantAuthToken } from './merchantAuthToken'
import { putPaymentAccount } from './paymentAccounts'

import { getProfile, postChangePassword, putAdministrator } from './profile'
import {
  getMerchantByID,
  getMerchantIDs,
  getMerchants,
  getMerchantStatsByID,
  postMerchant,
  deleteMerchant,
  putMerchant, clearMerchantData,
} from './merchants'
import { postTerminalGenerateToken, putTerminal } from './terminals'

const api = {
  postLogIn,
  getDashboard,
  getProfile,
  putAdministrator,
  postChangePassword,
  putBranch,
  getMerchantByID, getMerchantIDs, getMerchants, getMerchantStatsByID, postMerchant, deleteMerchant, putMerchant,
  postMerchantAuthToken,
  putTerminal, postTerminalGenerateToken,
  putPaymentAccount,
  clearMerchantData,
}

export default api
