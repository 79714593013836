import { Currencies, Money } from 'ts-money'
import Branch, { BranchJSON } from './Branch'
import { SalesData, SalesDataJSON } from './Dashboard'
import Manager, { ManagerJSON } from './Manager'
import PaymentAccount, { PaymentAccountJSON } from './PaymentAccount'
import Terminal, { TerminalJSON } from './Terminal'

export default class Merchant {
  id: number
  name: string
  branches: Branch[]
  terminals: Terminal[]
  managers: Manager[]
  paymentAccounts: PaymentAccount[]
  createdAt: Date
  lastActiveAt?: Date
  deletedAt?: Date

  constructor(json: MerchantJSON) {
    this.id = json.id
    this.name = json.name
    this.branches = json.branches.map(branchJSON => new Branch(branchJSON))
    this.terminals = json.terminals.map(terminalJSON => new Terminal(terminalJSON))
    this.managers = json.managers.map(managerJSON => new Manager(managerJSON))
    this.paymentAccounts = json.paymentAccounts.map(paymentAccountJSON => new PaymentAccount(paymentAccountJSON))
    this.createdAt = new Date(json.createdAt)
    if (json.lastActiveAt != null) {
      this.lastActiveAt = new Date(json.lastActiveAt)
    }
    if (json.deletedAt != null) {
      this.deletedAt = new Date(json.deletedAt)
    }
  }

  get [Symbol.toStringTag]() {
    return 'Merchant'
  }
}

class MerchantData {
  address: string | null
  gender: string | null
  dateOfBirth: string | null

  constructor(json: MerchantDataJSON) {
    this.address = json.address
    this.gender = json.gender
    this.dateOfBirth = json.dateOfBirth
  }

  get [Symbol.toStringTag]() {
    return 'MerchantData'
  }
}

export class MerchantStats {
  merchantID: number
  numberOfInvoices: number
  lifetimeEarnings: Money
  averageBasketSize: Money
  timeSeries: SalesData[]

  constructor(json: MerchantStatsJSON) {
    this.merchantID = json.merchantID
    this.numberOfInvoices = json.numberOfInvoices
    this.lifetimeEarnings = Money.fromInteger(json.lifetimeEarnings, Currencies.MYR)
    this.averageBasketSize = Money.fromInteger(json.averageBasketSize, Currencies.MYR)
    this.timeSeries = json.timeSeries.map(tsJson => new SalesData(tsJson))
  }

  get [Symbol.toStringTag]() {
    return 'MerchantStats'
  }
}

export interface MerchantJSON {
  id: number
  name: string
  branches: BranchJSON[]
  terminals: TerminalJSON[]
  managers: ManagerJSON[]
  paymentAccounts: PaymentAccountJSON[]
  createdAt: string
  lastActiveAt?: string
  deletedAt?: string
  extraData: MerchantDataJSON
}

interface MerchantDataJSON {
  address: string
  gender: string
  dateOfBirth: string
}

export interface MerchantStatsJSON {
  merchantID: number
  numberOfInvoices: number
  lifetimeEarnings: number
  averageBasketSize: number
  timeSeries: SalesDataJSON[]
}

export interface MerchantFilters {
  lastPurchasedProduct: number[] | null
  frequencyOfVisits: FrequencyOfVisitsFilter | null
  numberOfVisits: NumberOfVisitsFilter | null
}

export interface FrequencyOfVisitsFilter {
  s: string
  e: string
  n: number
  c: number
}

export interface NumberOfVisitsFilter {
  n: number
  c: number
}