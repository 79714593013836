import { Money } from 'ts-money'
import Category from './Category'
import CategoryValue, { CategoryValueJSON } from './CategoryValue'
import { ProductQuantity, ProductQuantityJSON } from './Promo'

export default class Dashboard {
  platformStats: {
    paidCount: number
    unpaidCount: number
    totalSalesAmount: Money
    totalUnpaidSalesAmount: Money
    uniqueCustomers: number
    newCustomers: number
    merchantsCount: number,
    branchesCount: number,
    timeSeries: SalesData[]
  }

  constructor(json: DashboardJSON) {
    this.platformStats = {
      paidCount:              json.platformStats.paidCount,
      unpaidCount:            json.platformStats.unpaidCount,
      totalSalesAmount:       Money.fromInteger(json.platformStats.totalSalesAmount, 'MYR'),
      totalUnpaidSalesAmount: Money.fromInteger(json.platformStats.totalUnpaidSalesAmount, 'MYR'),
      uniqueCustomers:        json.platformStats.uniqueCustomers,
      newCustomers:           json.platformStats.newCustomers,
      merchantsCount:         json.platformStats.merchantsCount,
      branchesCount:          json.platformStats.branchesCount,
      timeSeries:             json.platformStats.timeSeries.map(tsJson => new SalesData(tsJson)),
    }
  }

  get [Symbol.toStringTag]() {
    return 'Dashboard'
  }
}

export interface DashboardJSON {
  platformStats: {
    paidCount: number
    unpaidCount: number
    totalUnpaidSalesAmount: number
    totalSalesAmount: number
    uniqueCustomers: number
    newCustomers: number
    merchantsCount: number,
    branchesCount: number,
    timeSeries: SalesDataJSON[]
  }
}

export class SalesData {
  time: Date
  grossTotal: Money
  netTotal: Money
  invoiceCount: number

  constructor(json: SalesDataJSON) {
    this.time = new Date(json.time)
    this.grossTotal = Money.fromInteger(json.grossTotalAmount, 'MYR')
    this.netTotal = Money.fromInteger(json.netTotalAmount, 'MYR')
    this.invoiceCount = json.invoiceCount
  }
}

export interface SalesDataJSON {
  time: string
  grossTotalAmount: number
  netTotalAmount: number
  invoiceCount: number
}
