import Merchant, { MerchantFilters, MerchantJSON, MerchantStats, MerchantStatsJSON } from '../model/Merchant'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getMerchants = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: MerchantFilters, search: string): Promise<MerchantsResponse> => {

  // Prepare filters
  let flattenedFilters = getFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`merchants`, {
    sorting,
    page,
    limit,
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchants: merchantsJSON, paginationInfo } = await parseResponse(response)

    let merchants = merchantsJSON.map((merchantJSON: MerchantJSON) => new Merchant(merchantJSON))

    return {
      merchants,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getMerchantIDs = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: MerchantFilters, search: string): Promise<MerchantIDsResponse> => {

  // Prepare filters
  let flattenedFilters = getFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`merchant-ids`, {
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { merchantIDs } = await parseResponse(response)

    return {
      merchantIDs,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

const getFlattenedFilters = (filters: MerchantFilters): any => {
  // Prepare filters
  let flattenedFilters = {
    lastPurchasedProduct: filters.lastPurchasedProduct,
    frequencyOfVisits:    JSON.stringify(filters.frequencyOfVisits),
    numberOfVisits:       JSON.stringify(filters.numberOfVisits),
  }
  if (filters.lastPurchasedProduct == null || filters.lastPurchasedProduct.length == 0) {
    // @ts-ignore
    delete flattenedFilters.lastPurchasedProduct
  }
  if (filters.frequencyOfVisits == null) {
    // @ts-ignore
    delete flattenedFilters.frequencyOfVisits
  }
  if (filters.numberOfVisits == null) {
    // @ts-ignore
    delete flattenedFilters.numberOfVisits
  }
  return flattenedFilters
}

export const getMerchantByID = async (merchantID: number): Promise<Merchant> => {

  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchant } = await parseResponse(response)
    return new Merchant(merchant as MerchantJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID })
  }

}

export const getMerchantStatsByID = async (merchantID: number): Promise<MerchantStats> => {

  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}/stats`)

  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { stats } = await parseResponse(response)
    return new MerchantStats(stats as MerchantStatsJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID })
  }

}

export const putMerchant = async (merchantID: number, name: string): Promise<Merchant> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { merchant } = await parseResponse(response)
    return new Merchant(merchant as MerchantJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID, name })
  }
}

export const deleteMerchant = async (merchantID: number): Promise<Merchant> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchant } = await parseResponse(response)
    return new Merchant(merchant as MerchantJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID })
  }
}

export const clearMerchantData = async (merchantID: number): Promise<Merchant> => {
  // Build request
  const url = urlForEndpoint(`merchants/${merchantID}/clear`)

  const request = newRequest(HTTPMethods.POST, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { merchant } = await parseResponse(response)
    return new Merchant(merchant as MerchantJSON)
  } catch (err) {
    throw new LoopError(err, { merchantID })
  }
}


export const postMerchant = async (merchantName: string, brn: string, managerName: string, emailAddress: string, branchName: string, branchAddress: string, branchPhoneNumber: string, terminalSerialNumber: string): Promise<Merchant> => {
  // Build request
  const url = urlForEndpoint(`merchants`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    merchantName,
    brn,
    managerName,
    emailAddress,
    branchName,
    branchAddress,
    branchPhoneNumber,
    terminalSerialNumber,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response

    const { merchant } = await parseResponse(response)
    return new Merchant(merchant as MerchantJSON)
  } catch (err) {
    throw new LoopError(err, {
      merchantName,
      brn,
      managerName,
      emailAddress,
      branchName,
      branchAddress,
      branchPhoneNumber,
    })
  }
}

export interface MerchantIDsResponse {
  merchantIDs: number[]
  requestParams: MerchantsRequestParams
}


export interface MerchantsResponse {
  merchants: Merchant[]
  paginationInfo: PaginationInfo
  requestParams: MerchantsRequestParams
}

export interface MerchantsErrorResponse {
  error: Error
  requestParams: MerchantsRequestParams
}

export interface MerchantsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
  filters: MerchantFilters
}

export interface MerchantByIDRequestParams {
  merchantID: number
}

export interface MerchantByIDErrorResponse {
  error: Error
  requestParams: MerchantByIDRequestParams
}

export interface PostMerchantRequestParams {
  merchantName: string
  brn: string
  managerName: string
  emailAddress: string
  branchName: string
  branchAddress: string
  branchPhoneNumber: string
  terminalSerialNumber: string
}

export interface PostMerchantErrorResponse {
  error: Error
  requestParams: PostMerchantRequestParams
}

export interface PutMerchantRequestParams {
  merchantID: number
  merchantName: string
}

export interface PutMerchantErrorResponse {
  error: Error
  requestParams: PutMerchantRequestParams
}