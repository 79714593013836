import 'nprogress/nprogress.css'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import App from 'src/app/App'
import { SidebarProvider } from 'src/contexts/SidebarContext'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <HelmetProvider>
      <SidebarProvider>
        <App/>
      </SidebarProvider>
    </HelmetProvider>,
    document.getElementById('root')
  )
})
