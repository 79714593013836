import Dashboard, { DashboardJSON } from '../model/Dashboard'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getDashboard = async (period: string, branchID?: number): Promise<DashboardResponse> => {

  // Build request
  const url = urlForEndpoint(`dashboard`, {
    period,
    branchID,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Handle errors and return response
  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    const dashboardJSON = await parseResponse(response) as DashboardJSON

    let dashboard = new Dashboard(dashboardJSON)

    return {
      dashboard,
    }
  } catch (err) {
    throw new LoopError(err, { period })
  }
}

export interface DashboardResponse {
  dashboard: Dashboard
}

export interface DashboardErrorResponse {
  error: Error
  requestParams: DashboardRequestParams
}

export interface DashboardRequestParams {
  period: 'week' | 'month' | 'today' | 'last-week' | 'last-month'
}
